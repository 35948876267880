import React, {useEffect, useState} from "react";
import "./Transactions.css";
import {ArrowDropDownOutlined} from "@material-ui/icons";
import {ApiDetails, KEY} from "../../dummyData";
import {motion} from "framer-motion";
import {DataGrid} from "@mui/x-data-grid";
import CryptoJS from "crypto-js";

export default function Transactions(props) {

    let details = CryptoJS.AES.decrypt(
        localStorage.getItem("tenant_details"),
        KEY
    );

    let originalText = details.toString(CryptoJS.enc.Utf8);

    useEffect(() => {
        fetch(ApiDetails + "pegasus/visionary/tenant/get/tenant/transactions", {
            method: "POST",
            body: JSON.stringify({
                tenant_id: new Map(
                    Object.entries(JSON.parse(originalText))
                ).get("tenant_id"),
            }),
        })
            .then(async (response) => {
                let transactions = await response.json();
                if (response.status === 200) {
                    //fetch property units
                    setTransactions(transactions);
                } else {
                    props.snackBar({
                        text: "Failed fetching tenant details.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Call Customer Care For Assistance.",
                    type: "error",
                });
            });
    }, []);

    const [transactions, setTransactions] = useState([]);
    const [view, setView] = useState(true);

    const columns = [
        {
            field: "transaction_id",
            headerName: "Transaction ID",
            headerAlign: "center",
            align: "center",
            width: 200,
        },
        {
            field: "phone_number",
            headerName: "Phone number",
            headerAlign: "center",
            align: "center",
            width: 200,
        },
        {
            field: "amount",
            headerName: "Amount",
            headerAlign: "center",
            align: "center",
            width: 150,
            renderCell: (params) => {
                return (
                    <div className="userListUser">
                        {params.row.amount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "KSH",
                        })}
                    </div>
                );
            },
        },
        {
            field: "date_created",
            align: "center",
            headerAlign: "center",
            headerName: "Date",
            width: 200,
        },
    ];

    return (
        <div>
            <motion.div
                whileHover={{scale: 0.99}}
                onClick={() => {
                    // setView(!view)
                }}
                className="transactions"
            >
                VIEW TRANSACTIONS
                <ArrowDropDownOutlined/>
            </motion.div>
            <motion.div
                animate={{
                    scale: view ? 1 : 0,
                    transitionEnd: view
                        ? {
                            display: "",
                        }
                        : {
                            display: "none",
                        },
                }}
                transition={{duration: 1}}
                whileDrag={{scale: 1.1}}
                style={{
                    height: "400px",
                    maxWidth: "calc( 100% - 40px)",
                    margin: "0 20px 20px 20px",
                }}
            >
                <DataGrid
                    rows={transactions}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    disableSelectionOnClick
                    // checkboxSelection
                />
            </motion.div>
        </div>
    );
}
