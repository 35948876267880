import React, {useEffect, useState} from "react";
import "./topbar.css";
import {Logout, NotificationsNone, Reorder, Settings,} from "@mui/icons-material";
import {Avatar, Menu, MenuItem, useMediaQuery} from "@mui/material";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import {Link} from "react-router-dom";
import CryptoJS from "crypto-js";
import {KEY} from "../../dummyData";

export default function Topbar(props) {

    let originalText;

    const [display, setDisplay] = useState(true);
    if (localStorage.getItem("tenant_details") === null) {

        let dummyData = {
            first_name: "",
            last_name: "",

        }

        originalText = JSON.stringify(dummyData)
    } else {

        let details = CryptoJS.AES.decrypt(
            localStorage.getItem("tenant_details"),
            KEY
        );
        originalText = details.toString(CryptoJS.enc.Utf8);

    }

    const matches = useMediaQuery("(max-width:700px)");
    const [forceRefresh, setForceRefresh] = useState(false);
    const [notificationsNumber] = React.useState(6);

    useEffect(() => {
        try {
            let tenantDetails = new Map(
                Object.entries(JSON.parse(originalText))
            );
            setInitialName(
                tenantDetails.get("first_name") + " " + tenantDetails.get("last_name")
            );
            if (matches) {
                setName(tenantDetails.get("first_name"));
            } else {
                setName(
                    tenantDetails.get("first_name") + " " + tenantDetails.get("last_name")
                );
            }
        } catch (e) {
        }
    }, [forceRefresh, matches]);

    const [initialName, setInitialName] = useState("new user");
    const [name, setName] = React.useState(initialName);

    const getInitials = function (string) {
        let names = string.split(" "),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = (event) => {
        // 👇️ toggle class on click
        localStorage.clear();
    };
    const [sideBarDisplay, setSidebarDisplay] = useState("none");

    return {
        setForceRefresh,
        render: (
            <div className="topbar">
                <div className="topbarWrapper">
                    <div className="topLeft">
                        {matches ? (
                            <div className={"reorder"}>
                                <Reorder
                                    onClick={() => {
                                        setSidebarDisplay("");
                                        props("");
                                    }}
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                        <div>
              <span
                  style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      color: "#398DD2",
                  }}
              >
                Welcome, {name.toUpperCase()}
              </span>
                        </div>
                    </div>
                    <div className="topRight">
                        <div
                            className="topbarIconContainer"
                            style={{
                                display: "none",
                            }}
                        >
                            <NotificationsNone style={{color: "white", fontSize: "25px"}}/>
                            <span className="topIconBadge">{notificationsNumber}</span>
                        </div>

                        {/*<div className="topbarIconContainer">*/}
                        {/*  <Settings style={{ color: "white", fontSize: "25px" }} />*/}
                        {/*  <span className="topIconBadge">6</span>*/}
                        {/*</div>*/}

                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                textAlign: "center",
                            }}
                        >
                            <Tooltip title="Account settings">
                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    aria-controls={open ? "account-menu" : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                >
                                    <Avatar sx={{width: 45, height: 45}}>
                                        {getInitials(name)}
                                    </Avatar>
                                </IconButton>
                            </Tooltip>
                        </Box>

                        <Menu
                            anchorEl={anchorEl}
                            // id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            transformOrigin={{horizontal: "right", vertical: "top"}}
                            anchorOrigin={{horizontal: "right", vertical: "bottom"}}
                        >
                            {/*<MenuItem>*/}
                            {/*    <Avatar*/}
                            {/*        style={{*/}
                            {/*            marginRight: "10px",*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*    Profile*/}
                            {/*</MenuItem>*/}
                            {/*<MenuItem>*/}
                            {/*    <Avatar*/}
                            {/*        style={{*/}
                            {/*            marginRight: "10px",*/}
                            {/*        }}*/}
                            {/*    />{" "}*/}
                            {/*    My account*/}
                            {/*</MenuItem>*/}
                            {/*<Divider/>*/}
                            {/*<MenuItem disabled={true}>*/}
                            {/*    <ListItemIcon*/}
                            {/*        style={{*/}
                            {/*            marginRight: "10px",*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        <Settings fontSize="small"/>*/}
                            {/*    </ListItemIcon>*/}
                            {/*    Settings*/}
                            {/*</MenuItem>*/}

                            <MenuItem>
                                <Link onClick={logout} className="linkItem" to={"/"}>
                                    <ListItemIcon
                                        style={{
                                            marginRight: "10px",
                                        }}
                                    >
                                        <Logout fontSize="small"/>
                                    </ListItemIcon>
                                    Logout
                                </Link>
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
            </div>
        ),
    };
}
