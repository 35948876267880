import React, {useEffect, useState} from "react";
import "./Arrears.css";
import {ArrowDropDownOutlined, BugReport} from "@material-ui/icons";
import {motion} from "framer-motion";
import {DataGrid} from '@mui/x-data-grid';
import {ApiDetails, KEY} from "../../dummyData";
import Tooltip from "@mui/material/Tooltip";
import CryptoJS from "crypto-js";

export default function Arrears(props) {

    let details = CryptoJS.AES.decrypt(
        localStorage.getItem("tenant_details"),
        KEY
    );

    let originalText = details.toString(CryptoJS.enc.Utf8);

    useEffect(() => {
        getArrears();
    }, []);

    const getArrears = () => {
        fetch(ApiDetails + "pegasus/visionary/tenant/get/tenant/arrears", {
            method: "POST",
            body: JSON.stringify({
                tenant_id: new Map(Object.entries(JSON.parse(originalText))).get("tenant_id"),
            }),
        })
            .then(async (response) => {
                let arrears = await response.json();
                if (response.status === 200) {
                    setArrears(arrears);
                } else {
                    props.snackBar({
                        text: "Failed fetching tenant details.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Call Customer Care For Assistance.",
                    type: "error",
                });
            });
    }

    const markArrearDirty = (id, dirty) => {
        //Add arrear to database
        let body = {
            id: id,
            dirty: dirty,
        };

        fetch(ApiDetails + "pegasus/visionary/tenant/arrear/dirty", {
            method: "POST",
            body: JSON.stringify(body),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    getArrears();
                } else {
                    props.snackBar({
                        text: "Something Went Wrong. Try Again Later",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Call Customer Care For Assistance.",
                    type: "error",
                });
            });
    };

    const [arrears, setArrears] = useState([])
    const [view, setView] = useState(true)

    const columns = [
        {
            field: "description",
            headerName: "Description",
            headerAlign: "center",
            align: "center",
            width: 200,
            sortOrder: "asc",
        },
        {
            field: "amount",
            headerName: "Amount",
            headerAlign: "center",
            align: "center",
            width: 150,
            renderCell: (params) => {
                return (
                    <div className="userListUser">
                        {params.row.balance.toLocaleString("en-US", {
                            style: "currency",
                            currency: "KSH",
                        })}
                    </div>
                );
            },
        },
        {
            field: "date_created",
            headerAlign: "center",
            align: "center",
            headerName: "Date",
            width: 200,
            renderCell: (params) => {
                return (
                    <div
                        style={{
                            fontSize: "12px",
                        }}
                    >
                        {params.row.date_created}
                    </div>
                );
            },
        },
        {
            field: "",
            headerName: "Action",
            width: 100,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                return (
                    <div className="userListAction">
                        {params.row.dirty === "TRUE" ? (
                            <>
                                <Tooltip title="Remove Issue" placement="bottom-start">
                                    <BugReport
                                        style={{
                                            color: "red",
                                        }}
                                        onClick={() => {
                                            //open confirm delete modal
                                            //Delete arrear
                                            markArrearDirty(params.row.id, "FALSE");
                                        }}
                                    />
                                </Tooltip>
                            </>
                        ) : (
                            <>
                                <Tooltip title="Raise Issue" placement="bottom-start">
                                    <BugReport
                                        style={{color: "green"}}
                                        onClick={() => {
                                            //open confirm delete modal
                                            //Delete arrear
                                            markArrearDirty(params.row.id, "TRUE");
                                        }}
                                    />
                                </Tooltip>
                            </>
                        )}
                    </div>
                );
            },
        },
    ];

    return (

        <div>
            <motion.div
                whileHover={{scale: 0.99}}
                onClick={() => {
                    // setView(!view)
                }}
                className="arrears">

                DETAILS OF ARREARS

                <ArrowDropDownOutlined/>
            </motion.div>
            <motion.div
                animate={{
                    scale: view ? 1 : 0, transitionEnd: view ? {
                        display: "",
                    } : {
                        display: "none",
                    },
                }}
                transition={{duration: 1}}
                whileDrag={{scale: 1.1}}
                style={{height: "400px", maxWidth: 'calc( 100% - 40px) ', margin: "0 20px",}}>

                <DataGrid
                    rows={arrears}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    disableSelectionOnClick
                    // checkboxSelection
                />
            </motion.div>
        </div>

    );
}
